<template>
  <div class="container">
    <div class="row">
      <div class="col-md-4 shadow-none mx-auto pt-2 rounded">
        <div class="card">
          <div class="card-body text-center">
            <i
              class="fa fa-user-circle-o text-info fa-2"
              aria-hidden="true"
            />
            <div class="signin-label mb-4">
              Sign Up
            </div>

            <form @submit.prevent="handleSubmit">
              <div
                class="form-group input-group-sm mb-3"
                :class="{'form-group--error': $v.name.$error}"
              >
                <div class="input-label font-weight-bold text-left">
                  Name
                </div>
                <input
                  v-model.trim="$v.name.$model" 
                  type="text" 
                  class="form-control"
                  placeholder="Name"
                  required="true"
                >
                <!-- <div class="error" v-if="!$v.name.required">
                  Field is required</div> 
                -->
                <!-- <div class="error" v-if="!$v.name.minLength">
                  Name must have at least 
                  {{$v.name.$params.minLength.min}} 
                  letters.
                </div> -->
              </div>

              <div class="form-group input-group-sm mb-3">
                <div class="input-label font-weight-bold text-left">
                  Email
                </div>
                <input
                  v-model="user.email" 
                  type="email" 
                  class="form-control" 
                  placeholder="e-mail (test@example.com)" 
                  :class="{'is-invalid': submitted && !user.email}"
                >
                <div
                  v-if="submitted && !user.email"
                  class="invalid-feedback"
                >
                  Email is required.
                </div>
              </div>

              <div class="form-group input-group-sm mb-3">
                <div class="input-label font-weight-bold text-left">
                  Bill Unit No
                </div>
                <input
                  v-model="user.billunitnumber" 
                  type="text"
                  class="form-control" 
                  placeholder="Bill Unit No"
                  :class="{'is-invalid': submitted && !user.billunitnumber}"
                >
                <div
                  v-if="submitted && !user.billunitnumber"
                  class="invalid-feedback"
                >
                  Bill Unit No is required.
                </div>
              </div>

              <div class="form-group input-group-sm mb-3">
                <div class="input-label font-weight-bold text-left">
                  Quarter
                </div>
                <input
                  v-model="user.quarter" 
                  type="text"
                  class="form-control" 
                  placeholder="Quarter Number" 
                  :class="{'is-invalid': submitted && !user.quarter}"
                >
                <div
                  v-if="submitted && !user.quarter"
                  class="invalid-feedback"
                >
                  Quarter Number is required.
                </div>
              </div>
              
              <div class="form-group input-group-sm mb-3">
                <div class="input-label font-weight-bold text-left">
                  Address
                </div>
                <input
                  v-model="user.address" 
                  type="text"
                  class="form-control" 
                  placeholder="Address" 
                  :class="{'is-invalid': submitted && !user.address}"
                >
                <div
                  v-if="submitted && !user.address"
                  class="invalid-feedback"
                >
                  Address is required.
                </div>
              </div>

              <div class="form-group input-group-sm mb-3">             
                <div class="input-label font-weight-bold text-left">
                  Password
                </div>
                <input 
                  v-model="user.password" 
                  type="text" 
                  class="form-control"
                  :class="{'is-invalid': submitted && !user.password}" 
                  placeholder="Password"
                >
                <div
                  v-show="submitted && !user.password"
                  class="invalid-feedback"
                >
                  Password is required
                </div>
              </div>

              <div class="form-group input-group-sm mb-3">
                <div class="input-label font-weight-bold text-left">
                  Password Confirmation
                </div>
                <input 
                  v-model="user.password_confirmation" 
                  type="password" 
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && 
                      !user.password_confirmation
                  }" 
                  placeholder="Password Confirmation"
                >
                <div
                  v-show="submitted && !user.password_confirmation"
                  class="invalid-feedback"
                >
                  Password Confirmation is required
                </div>
              </div>
              
              <button
                type="submit"
                class="btn btn-md btn-info mt-3"
              >
                Register
              </button> 
            </form>
            <p class="remember-me-label mt-3">
              Already have an account? 
              <span class="sign-up-label"> 
                <router-link to="/login">Sign In</router-link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from 'vuex';
import { validationMixin } from "vuelidate";
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'Register',
  mixins: [validationMixin],
  data () {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      submitted: false
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
    }
  },
  computed: {
    ...mapState('auth', ['status'])
  },
  methods: {
    ...mapActions('auth', ['register']),
    handleSubmit() {
      this.submitted = true;
      // this.$validator.validate().then(valid => {
      //     if (valid) {
      // this.register(this.user);
      //     }
      // });
    }
  }
}
</script>

<style scoped>
  .signin-label{ font-size: 1.2rem; color: #656262; }
	.remember-me-label{ font-size: 0.8rem; vertical-align: middle; color: #656262; }
	.forgot-password{ font-size: 0.8rem; color: #00b7c2; }
	.sign-up-label{ font-size: 0.8rem; color: #0f4c75; }
	.input-label{ font-size: 14px; color: #656262; }
	.fa-2{ font-size: 1.4em; }
</style>